import { render } from "./BusinessHeader.vue?vue&type=template&id=23364e1d&scoped=true"
import script from "./BusinessHeader.vue?vue&type=script&lang=js"
export * from "./BusinessHeader.vue?vue&type=script&lang=js"

import "./BusinessHeader.vue?vue&type=style&index=0&id=23364e1d&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-23364e1d"
/* hot reload */
if (module.hot) {
  script.__hmrId = "23364e1d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('23364e1d', script)) {
    api.reload('23364e1d', script)
  }
  
  module.hot.accept("./BusinessHeader.vue?vue&type=template&id=23364e1d&scoped=true", () => {
    api.rerender('23364e1d', render)
  })

}

script.__file = "src/components/BusinessHeader.vue"

export default script