<template>
  <div>
    <ul class="flex flex-row w-full pb-4">
      <router-link
        :to="`/businesses/${business_id}/locations`"
        :class="currentRoute == 'locations' ? 'active' : ''"
        class="text-center px-5 py-2"
      >
        Locations
      </router-link>
      <router-link
        :to="`/businesses/${business_id}/users`"
        :class="currentRoute == 'users' ? 'active' : ''"
        class="text-center px-7 py-2"
      >
        Users
      </router-link>
      <router-link
        :to="`/businesses/${business_id}/mass-text-history`"
        :class="currentRoute == 'mass-text-history' ? 'active' : ''"
        class="text-center px-7 py-2"
      >
        Mass Text History
      </router-link>
      <router-link
        :to="`/businesses/${business_id}/web-chat`"
        :class="currentRoute == 'web-chat' ? 'active' : ''"
        class="text-center px-7 py-2"
      >
        Web Chat
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  data: () => ({
    business_id: "_",
    currentRoute: "",
  }),
  mounted() {
    this.business_id = this.$route.params.business_id;
    this.currentRoute = this.$route.path.split("/").pop();
  },
  methods: {},
};
</script>

<style scoped>
.active {
  color: #4399e1;
  border-bottom: 2px #4399e1 solid;
}
</style>
